@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@700&display=swap");

.App {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
}

.flex-card-list {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  box-sizing: border-box;
}

.flex-card-listitem {
  display: flex;
  width: 100%;
}

.full-width {
  flex: 1 0 100%;
}

/* Code to adjust the layout to 3 cards per row */
.flex-card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin: 0.5rem;
  width: 100%; /* For responsive svg */
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.flex-card-content {
  display: flex;
  flex: 1 0 auto; /* We have to add a basis for IE10/11 */
  flex-direction: column;
}

/*fix dragging on mobile*/
.react-grid-item {
  touch-action: auto !important;
}

.app-title {
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-wrap: wrap;
}

h1 {
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  font-variant: small-caps;
  font-weight: 700;
  letter-spacing: 1.7px;
  text-align: center;
  font-size: 2.2em;
  order: -1;
  margin: 15px 10px -15px 10px;
}

.title-numerals {
  font-size: 0.73em;
}

h2.title-date {
  text-align: center;
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  font-size: 1.6em;
  font-weight: 400;
}

.chart-title {
  display: inline;
  font-family: "HelveticaNeue-CondensedBold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  font-stretch: condensed;
  text-transform: capitalize;
  text-align: left;
  margin-bottom: 0.3em;
}

.footer {
  font-size: 0.8em;
  width: 100%;
  text-align: center;
}

/* View buttons */
.nav {
  display: flex;
  justify-content: center;
}

.view-button {
  display: inline-block;
  margin: 0 0.5rem;
  padding: 0.3rem 1rem;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: black;
  background-color: white;
  border: 1.3px solid silver;
  border-bottom: 0;
  border-radius: 5px;
  box-shadow: 0px 6px 0px #666, 0px 3px 9px rgba(0, 0, 0, 0.3);

  background-image: linear-gradient (#eee, white);
  background-image: -o-linear-gradient (#eee, white);
  background-image: -moz-linear-gradient (#eee, white);
  background-image: -webkit-linear-gradient (#eee, white);
  background-image: -ms-linear-gradient (#eee, white);

  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, #eee),
    color-stop(1, white)
  );
}

.view-button:hover {
  background-image: linear-gradient (#ddd, #f2f2f7);
  background-image: -o-linear-gradient (#ddd, #f2f2f7);
  background-image: -moz-linear-gradient (#ddd, #f2f2f7);
  background-image: -webkit-linear-gradient (#ddd, #f2f2f7);
  background-image: -ms-linear-gradient (#ddd, #f2f2f7);

  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, #ddd),
    color-stop(1, #f2f2f7)
  );
}

.view-button:active {
  box-shadow: 0 2px #555;
  transform: translateY(5px);
}

.active {
  box-shadow: 0 2px #555;
  transform: translateY(5px);
}

/* Radio buttons */

.radio-btn-container {
  display: flex;
  margin-bottom: 10px;
}

.radio-btn > label {
  margin-right: 8px;
}

.radio-btn > input {
  margin-right: 4px;
}

/* Tables */

.area-data-sets {
  display: flex;
  flex-wrap: wrap;
}

.data-set {
  margin: auto;
  margin-bottom: 5px;
  width: 7.5em;
  height: 9.5em;
  text-align: center;
  border: 2px solid black;
  border-radius: 15px;
}

.data-set .selected {
  color: #e25a42;
}

div.vertical-inverted-title {
  background-color: black;
  line-height: 28px;
  white-space: nowrap;
  color: white;
  font-size: 1em;
  font-weight: 700;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  padding-top: 7px;
  text-align: left;
  float: left;
  height: 9.2em;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

div.set-title {
  font-family: "helveticaneue-condensedbold", "helvetica neue", helvetica, arial,
    sans-serif;
  font-size: 0.8em;
  font-weight: 400;
  line-height: 0.4;
  margin-top: 10px;
  color: grey;
}

.numerical-data {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 1.1em;
}

.smaller-numbers {
  margin-top: -5px;
  font-size: 0.8em;
}

.small-table {
  display: block;
  float: right;
  /*margin-right: 10px;*/
  margin: 0.5rem 0;
  text-align: center;
  font-size: 1rem;
}

th,
td {
  padding: 0 3px 0 3px;
}

td.numerical-data {
  font-size: 1rem;
}

th {
  background-color: #282c34;
  border: 1px solid #282c34;
  border-radius: 5px;
  color: white;
  line-height: 1.5;
  font-size: 0.8em;
  font-weight: 700;
}

/* Default dropdown menu */
.dropdown-container {
  margin: 8px 0 8px 0;
}

select {
  font-size: 16px;
  text-transform: capitalize;
  max-width: 200px;
}

/* Charts */
.chart-container {
  height: 100%;
}

/* Pie charts */
svg.pie {
  display: block;
  margin: auto;
}

.pie > text {
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 32px;
  fill: #85b135;
}

p.footnote {
  font-size: 0.8em;
  text-align: left;
  color: #8e8e93;
}

a {
  color: #8e8e93;
}

/* Loading Spinner*/
.lds-spinner {
  color: official;
  display: block;
  position: relative;
  margin: auto;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: SlateGrey;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Collapsible */
.wrap-collapsible {
  margin: 1rem 0 0 0;
}

input[type="checkbox"] {
  display: none;
}

.lbl-toggle {
  display: block;

  font-weight: bold;
  font-family: monospace;
  font-size: 1rem;
  text-align: center;

  padding: 0.5rem;

  color: #c0c0bb;
  background: black;

  cursor: pointer;

  border-radius: 7px;
  transition: all 0.25s ease-out;
}

.lbl-toggle:hover {
  color: white;
}

.lbl-toggle::before {
  content: " ";
  display: inline-block;

  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid currentColor;

  vertical-align: middle;
  margin-right: 0.7rem;
  transform: translateY(-2px);

  transition: transform 0.2s ease-out;
}

.collapsible-content .content-inner {
  background: white;
  color: black;
  border: 1px solid black;
  /* border-bottom: 1px solid black; */
  /* border-right: 1px solid black; */

  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: 0.5rem 1rem;
}

.content-inner {
  font-family: serif;
  font-size: 1.1rem;
  line-height: 1.3;
}

.collapsible-content {
  max-height: 0px;
  overflow: hidden;

  transition: max-height 0.25s ease-in-out;
}

.toggle:checked + .lbl-toggle + .collapsible-content {
  max-height: 100%;
}

.toggle:checked + .lbl-toggle::before {
  transform: rotate(90deg) translateX(-3px);
}

.toggle:checked + .lbl-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
