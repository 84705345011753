.axisLabel {
  font-size: 1.5em;
  fill: #635f5d;
  color: #635f5d;
}

.marks {
  fill: #635f5d;
}

.tick text {
  fill: #635f5d;
  cursor: default;
  font-size: 1em;
}

.tick rect {
  fill: #ccc;
  opacity: 0.2;
}

.tick line {
  fill: none;
  stroke: #ccc;
}

.legendLabel {
  font-weight: bold;
  font-size: 1.1rem;
}

.legend text {
  font-size: 1.1rem;
}
